body {
  font-family: 400 15px Lato, sans-serif;
  line-height: 1.8;
  color: #818181;
}

h2 {
  font-size: 24px;
  text-transform: uppercase;
  color: #303030;
  font-weight: 600;
  margin-bottom: 30px;
}

h4 {
  font-size: 19px;
  line-height: 1.375em;
  color: #303030;
  font-weight: 400;
  margin-bottom: 30px;
}


.jumbotron {
  background-color: #5CB3FF !important;
  color: #fff !important;
  padding: 100px 25px;
  font-family: Montserrat, sans-serif;
  padding-top: 80px !important;
}

.container-fluid {
  padding: 60px 50px;
}

.bg-grey {
  background-color: #f6f6f6;
}

.logo-small {
  color: #f4511e;
  font-size: 50px;
}

.logo {
  color: #FFC300;
  font-size: 200px;
}

.navbar {
  margin-bottom: 0;
  background-color: #fff;
  z-index: 9999;
  border: 0;
  font-size: 12px !important;
  line-height: 1.42857143 !important;
  letter-spacing: 4px;
  border-radius: 0;
  font-family: Montserrat, sans-serif;
}

.navbar li a, .navbar .navbar-brand {
  color: #5CB3FF !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  line-height: 80px !important;
}

.navbar-nav li a:hover, .navbar-nav li.active a {
  color: #fff !important;
  background-color: #5CB3FF !important;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 80px;
}

footer .glyphicon {
  font-size: 20px;
  margin-bottom: 20px;
  color: #5CB3FF;
}

.slideanim {visibility:hidden;}
.slide {
  animation: slide;
  animation-duration: 2s;
  visibility: visible;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
